// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-views-pages-page-tsx": () => import("/Users/Chrille/Documents/hemma/frontend/src/views/pages/Page.tsx" /* webpackChunkName: "component---src-views-pages-page-tsx" */),
  "component---src-pages-404-index-tsx": () => import("/Users/Chrille/Documents/hemma/frontend/src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-privat-index-tsx": () => import("/Users/Chrille/Documents/hemma/frontend/src/pages/privat/index.tsx" /* webpackChunkName: "component---src-pages-privat-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/Chrille/Documents/hemma/frontend/.cache/data.json")

